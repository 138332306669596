import React, { ReactElement } from "react";
import { Center, Text, useColorModeValue, Button, Stack, Heading } from "@chakra-ui/react";
import { FaMicrosoft, FaGoogle, FaFacebook, FaTwitter } from "react-icons/fa";
interface Props {
  children?: React.ReactNode;
}

export default function Login({ children }: Props): ReactElement {
  const api = process.env.API ?? process.env.REACT_APP_API_URL ?? "http://localhost:5000";

  const handleGoogle = () => {
    window.location.href = api + "/user/login/google";
  };

  const handleMicrosoft = () => {
    window.location.href = api + "/user/login/microsoft";
  };

  const handleTwitter = () => {
    window.location.href = api + "/user/login/twitter";
  };

  const handleFacebook = () => {
    window.location.href = api + "/user/login/facebook";
  };

  return (
    <Center h="100vh" py={6} className="background">
      <Stack
        maxW={"445px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Center>
          <Heading>Sign in</Heading>
        </Center>
        <Button onClick={handleGoogle} w={"full"} variant={"outline"} leftIcon={<FaGoogle />}>
          <Center>
            <Text>Sign in with Google</Text>
          </Center>
        </Button>
        <Button onClick={handleMicrosoft} w={"full"} variant={"outline"} leftIcon={<FaMicrosoft />}>
          <Center>
            <Text>Sign in with Microsoft</Text>
          </Center>
        </Button>
        <Button onClick={handleFacebook} w={"full"} variant={"outline"} leftIcon={<FaFacebook />}>
          <Center>
            <Text>Sign in with Facebook</Text>
          </Center>
        </Button>
        <Button disabled={true} onClick={handleTwitter} w={"full"} variant={"outline"} leftIcon={<FaTwitter />}>
          <Center>
            <Text>Sign in with Twitter</Text>
          </Center>
        </Button>
      </Stack>
    </Center>
  );
}
