import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Navigate } from "react-router-dom";

export interface AuthState {
  isLoggedIn: boolean;
  at?: string;
  rt?: string;
  user?: {};
}

const initialState: AuthState = {
  isLoggedIn: false
};

export interface TokenSet {
  at: string;
  rt: string;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<TokenSet>) => {
      state.at = action.payload.at;
      state.rt = action.payload.rt;
      state.isLoggedIn = true;
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
      delete state.at;
      delete state.rt;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      delete state.at;
      delete state.rt;
    },
  }
});

export const { setLoggedIn, setLoggedOut, logOut } = authSlice.actions;

export default authSlice.reducer;
