import { ReactElement } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setLoggedIn } from "../../store/auth";

type LoginCookies = {
  at: string;
  rt: string;
  user: string;
};

export default function LoginForwarder(): ReactElement {
  const [loginCookies, setCookie] = useCookies<"at" | "rt" | "user", LoginCookies>(["at", "rt", "user"]);
  const dispatch = useDispatch();

  if (loginCookies.at && loginCookies.rt) {
    dispatch(setLoggedIn(loginCookies));

    return <Navigate to="/" />;
  }

  return <Navigate to="/failedLogin" />;
}
