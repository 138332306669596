import React, { ReactElement } from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  children?: React.ReactNode;
}

export default function FailedLogin({ children }: Props): ReactElement {
  return (
    <Box className="test" w="100%">
      Login Failed
    </Box>
  );
}
