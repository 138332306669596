import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../store";
import Auth from "./auth/Auth";
import NonAuth from "./non-auth/NonAuth";

interface Props {
  children?: React.ReactNode;
}

export default function Home({ children }: Props): ReactElement {
  const isLoggedIn = useSelector((state: IRootState) => state.auth.isLoggedIn);

  if (isLoggedIn) return <Auth />;

  return <NonAuth />;
}
