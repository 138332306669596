import React, { ReactElement } from "react";
import { Box, Center, Container, Text } from "@chakra-ui/react";
import NavBar from "../../components/NavBar";

interface Props {
  children?: React.ReactNode;
}

export default function Auth({ children }: Props): ReactElement {
  return (
    <Center h="100vh" py={6} className="background">
      <Container maxW={"7xl"} height={"full"}>
        <NavBar />
        <Box>
          <Text>test</Text>
        </Box>
      </Container>
    </Center>
  );
}
