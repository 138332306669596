import React, { ReactElement } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Spacer,
  Stack,
  useColorModeValue
} from "@chakra-ui/react";
import localizations from "../../library/localization";
import { useNavigate } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}

export default function NonAuth({ children }: Props): ReactElement {
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate("login");
  };

  return (
    <Center h="100vh" py={6} className="background">
      <Box
        maxW={"445px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Box h={"210px"} bg={"gray.100"} mt={-6} mx={-6} mb={6} pos={"relative"}>
          <Flex>
            <Center w={"full"} h={"210px"}>
              <Stack>
                <HStack>
                  <PinInput autoFocus={true} colorScheme="blue" size="lg" variant="filled">
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <Center>
                  <Button colorScheme="blue" size="lg">
                    Enter Quiz
                  </Button>
                </Center>
              </Stack>
            </Center>
          </Flex>
        </Box>
        <Flex w={"full"}>
          <Button p={4} onClick={handleClickLogin}>
            Log in
          </Button>
          <Spacer />
          <Button p={4}>Sign up</Button>
        </Flex>
      </Box>
    </Center>
  );
}
