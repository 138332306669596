import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginForwarder from "./screens/non-auth/LoginForwarder";
import FailedLogin from "./screens/non-auth/FailedLogin";
import Home from "./screens/Home";
import { useSelector } from "react-redux";
import { IRootState } from "./store";
import Login from "./screens/non-auth/Login";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

function ProtectedRoute(props: Props) {
  const isLoggedIn = useSelector((state: IRootState) => state.auth.isLoggedIn);

  return isLoggedIn ? props.children : <Navigate to="/" />;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="successfulLogin" element={<LoginForwarder />} />
        <Route path="successfulSignup" element={<LoginForwarder />} />
        <Route path="failedLogin" element={<FailedLogin />} />
        <Route path="login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
